


















































































































































































import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import CCToggle from '../components/CCToggle.vue';
import { SocketIOMixin } from '../io-utils';

@Component({
  components: {
    CCToggle,
  },
})
export default class AirQualityBuildings extends Mixins(SocketIOMixin) {
  public $store: any;
  public buildingsData: any = {};
  public buildingsWithAlerts: boolean = false;
  public viewType: string = 'cards';
  public loading: boolean = false;

  public created() {
    this.viewType = localStorage.getItem('dbBuildingViewType') || 'cards';
    this.buildingsWithAlerts = localStorage.getItem('dbBuildingsWithAlerts') === 'on';
    this.loading = true;
    this.loadBuildingsDataLast();
  }

  public mounted() {
    this.ioAttach();
  }

  public beforeDestroy() {
    this.ioClose();
  }

  private ioAttach() {
    const socket: any = this.ioListen('/qai-buildings');
    socket.on('indicators', (message: any) => {
      const buildingId = message['building_id'];
      const data = message['data'];
      // only data are sent, no thresholds
      let obj;
      if (this.buildingsData[buildingId] === undefined) {
        obj = {};
      } else {
        obj = this.buildingsData[buildingId];
      }
      obj = {...obj, data, online: true};
      Vue.set(this.buildingsData, buildingId, obj);
    });
  }

  @Watch('buildingsWithAlerts')
  private saveAreasWithAlerts() {
    localStorage.setItem('dbBuildingsWithAlerts', this.buildingsWithAlerts ? 'on' : 'off');
  }

  private loadBuildingsDataLast() {
    this.$store.dispatch('loadBuildingsDataLast')
    .then((response: any) => {
      this.buildingsData = response.data;
      this.loading = false;
    });
  }

  private setViewType(vt: string) {
    this.viewType = vt;
    localStorage.setItem('dbBuildingViewType', vt);
  }

  get cardCss() {
    if (this.$q.screen.width < 800) {
      return 'col-12';
    } else if (this.$q.screen.md) {
      return 'col-6';
    } else if (this.$q.screen.lg) {
      return 'col-4';
    } else if (this.$q.screen.xl) {
      return 'col-3';
    }
    return 'col-6';
  }

  get buildingsListWithAlerts() {
    return this.$store.state.buildings.filter((building: any) => {
      if (!this.isBuildingOnline(building)) {
        return;
      }
      if (this.buildingsData[building.id].data.qai > 0) {
        return building;
      }
    });
  }

  private isBuildingOnline(building: any) {
    return (
      this.buildingsData[building.id] !== undefined
      && this.buildingsData[building.id].online === true
    );
  }

  get buildingsList() {
    let buildings = this.$store.state.buildings;
    if (this.buildingsWithAlerts) {
      buildings = this.buildingsListWithAlerts;
    }
    return buildings.sort((a: any, b: any) => {
      const onA: boolean = this.isBuildingOnline(a);
      const onB: boolean = this.isBuildingOnline(b);
      if (onA === onB) {
        return a.name.localeCompare(b.name);
      } else {
        if (onA) { return -1; }
        return 1;
      }
    });
  }

  get buildingsListData() {
    return this.buildingsList.map((building: any) => {
      const online = this.isBuildingOnline(building);
      const result = {
        id: building.id,
        info: building,
        online,
        data: null,
        qai: null,
        thresholds: null,
        alertsIndicators: {},
      };
      if (online === true) {
        const data = this.buildingsData[building.id];
        const indicators = {};
        for (const [pollutant, thresholds] of Object.entries(data.info.thresholds)) {
          const value = data.data[pollutant];
          const entry: any = thresholds;
          if (value < entry.warning) {
            continue;
          }
          indicators[pollutant] = (value >= entry.critical) ? 'critical' : 'warning';
        }

        result.data = data.data;
        result.qai = data.data.qai;
        result.thresholds = data.info.thresholds;
        result.alertsIndicators = indicators;
      }
      return result;
    });
  }
}
